.imagePreviewContainer {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: min-content;
  grid-column-gap: 1.6rem;
  align-content: flex-end;
  margin-bottom: 0.8rem;
  margin-left: 1.6rem;
}

.imagePreview {
  position: relative;
  height: 10rem;
  width: 10rem;
  background-size: contain;
  background-position: center;
  border: 1px solid var(--border-dark);
  border-radius: 4px;
  background-repeat: no-repeat;
}

.closeIconTransition{
  transform: rotate(0deg);
  transition: .3s ease-in-out;
}
.closeIconTransition:hover{
  transform: rotate(90deg);
}