.pagination-container {
  /* display: flex;
  justify-content: space-between;
  align-items: center; */
  position: sticky;
  bottom: 0;
  left: 0;
  /* width: 100%; */
  /* background-color: white; */
  /* padding: var(--body-padding); */
  /* height: 7rem; */
  z-index: 500;
  float: right;
  /* width: 244px; */
}

.css-6q0nyr-Svg {
  fill: #000 !important;
  height: 17px;
}

.pagination-container .page-number-container .left_arrow {
  margin-right: 10px;
  padding: 0.8rem 0 0.8rem 1rem;
}

.asyncPaginateParent div.css-165luzk-option:last-child {
  text-align: center;
}
@media (max-width: 1200px) {
  .pagination-container {
    z-index: 10 !important;
  }
}
