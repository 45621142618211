.invenoty_Tabs .navmenu-component--buttons {
  height: calc(100vh - 97px) !important;
}

.inventory_tabs_Table .list-table td {
  padding: 0 0px !important;
}

.inventory_tabs_Table .list-table tbody td {
  padding-left: 6px !important;
}

.inventory_tabs_Table tbody td[data-heading="Status"] {
  line-height: 1.42857143 !important;
}

.inventory_tabs_Table .list-table tbody tr td:first-child, .inventory_tabs_Table .list-table thead tr th:first-child {
  padding-left: 4rem !important;
}

.inventory_tabs_Table .transaction_tab_table tr th:nth-child(4) {
  padding-left: 2.5rem !important;
}

.reset_password input {
  width: 25rem !important;
  padding: 6px 45px 6px 12px !important;
}

.reset_password svg {
  margin-top: 4px !important;
  margin-left: -4% !important;
  height: 23px !important;
  width: 23px !important;
}

.mismatch-passsword--errors.touched {
  position: absolute;
  left: 34%;
  top: 60%;
}

.left_0 {
  left: 0 !important;
  list-style: none !important;
}

.left_0 li {
  list-style: none !important;
}

@media(max-width:890px) {
  .invenoty_Tabs .navmenu-component--buttons {
    width: 23%;
  }
  .invenoty_Tabs .navmenu-component--content {
    width: 77%;
  }
}