.page-number-container,
.page-number-container > a,
.page-number {
  display: flex;
  align-items: center;
}

.page-number--num {
  padding: 0.5rem 1rem;
  border-radius: 4px;
}

.pagination-route .page-number--num.pointer:hover {
  color: var(--blue-500);
  background: unset;
}

.pagination-route .page-number-arrow--icon {
  width: 1.2rem;
  height: 1.2rem;
}

.pagination-route .page-number-arrow--icon path {
  stroke: var(--grey);
  stroke-width: 3px;
  fill: none;
}

.pagination-route .page-number-arrow--icon:hover path{
  stroke: var(--blue-500);
}

.pagination-route .page-number--num {
  color: var(--grey);
}

.pagination-route .page--active {
  color: var(--black);
  position: relative;
  font-weight: 500;
}
.pagination-route .page--active:after {
  content: none;
}
.disabled-link {
  pointer-events: none;
}
.pagination-input{
  width: 4.5rem;
  height: 3.2rem;
  overflow: hidden;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center !important;
}
.totalcount3.salesreturns{
  width: 155px;
}
.totalcount3.salesorders{
  width: 120px;
}
.totalcount3.creditnotes{
  width: 150px;
}
.totalcount3.item-adjustments{
  width: 170px;
}
.totalcount3.items{
  width : 110px;
}
.totalcount3.bills{
  width: 100px;
}
.totalcount3.purchaseorders{
  width: 120px;
}
.totalcount3.invoices{
  width: 120px;
}
.totalcount3.package_listing{
  width: 130px;
}
.show_total {
  float: left;
  color: #000;
  font-size: 14px;
  position: relative;
  top: 1px;
  margin-left: 0px;
  font-weight: 500;
  padding-right: 1rem;
}
.show_total{
  position: relative;
}
.totalcount3{
  position: absolute;
  right: 0;
  bottom: -39px;
  display: none;
  width: 140px;
  color: #FFF;
  background-color: #000;
  padding: 5px 0;
  text-align: center;
  transition: 0.3s;
  z-index: 9999999;
  font-size: 12px;
}
.show_total:hover .totalcount3{
  display: block;
}