.item-tag {
  padding: 0 10px;
  border-radius: 4px;
  background-color: #306ab4;
  color: #fff;
  /* margin-left: 5px; */
  /* margin: 3px; */
  background-color: #646bed;
}

.item-groups-tab {
  /*width: 78%;*/
  overflow: auto;
  padding: 0 !important;
  max-height: 50%;
}

.height_custom_item_group {
  max-height: calc(100vh - 533px);
  overflow: auto;
  float: left;
  width: 100%;
  padding: 0 20px;
}

.item-groups-table .sub_tabs_transaction_main thead th:last-child {
  float: none !important;
}

.item-groups-table .sub_tabs_transaction_main thead th {
  line-height: 1.42857143 !important;
}

/* .item-groups-table .list-table--item--col {
  max-width: 35rem !important;
} */

.item-groups-table table {
  position: relative;
}

.item-groups-table table thead th {
  height: 43px !important;
  position: sticky !important;
  top: -1px;
  z-index: 1;
  background: #e9e9e9;
}

.item-tag-main {
  display: flex;
}

.item-tag-main .item-tag {
  width: auto !important;
  margin-right: 4px;
}

.row_lable {
  margin: 0px;
  margin-top: 16px;
  /*margin-bottom: 5px;*/
  /*padding-bottom: 10px;*/
}

.topci_laebl {
  min-width: 160px;
  color: #888;
}

div.item-view {
  padding-bottom: 25px;
}

.field-row {
  display: flex;
  align-items: center;
  align-self: center;
  flex-direction: row;
  justify-content: space-between;
  width: 66%;
}

.heading_font {
  font-size: 1.4rem !important;
}

.bottom_line {
  border-top: 2px solid #f4f5f7 !important;
}

.item_group_summary_update .field-row {
  float: left;
  width: 100%;
  line-height: 34px;
}

.item_group_summary_update .close_icon {
  height: auto;
  top: 10px;
}

.item_group_summary_update .section-details--info {
  float: left;
  width: 64%;
  padding: 10px 20px;
  line-height: 30px;
}

/* .item_group_summary_update .section-details--info{float: left;width: 100%;padding: 10px 20px;} */

.item_group_summary_update .section-details--info hr {
  float: left;
  width: 100%;
}

.item_group_summary_update .section-details--info .field-row div:first-child:not(.item-tag) {
  float: left;
  width: 30%;
}

.item_group_summary_update .section-details--info .field-row div:last-child:not(.item-tag) {
  float: left;
  width: 70%;
  word-break: break-word;
}

.icon_top {
  top: 6px !important;
}

/* .item-group-main {
  display: flex;
  flex-wrap: wrap;
}

.item-group-main .item-tag {
  width: auto !important;
  display: flex;
  height: 25px;
  align-items: center;
  justify-content: center;
  font-size: 13px;
} */


.table.group_items_table th:nth-child(2),
.table.group_items_table td:nth-child(2),
.table.group_items_table th:nth-child(3),
.table.group_items_table td:nth-child(3),
.table.group_items_table th:nth-child(4),
.table.group_items_table td:nth-child(4),
.table.group_items_table th:nth-child(5),
.table.group_items_table td:nth-child(5) {
  padding-right: 2.4rem !important;
  text-align: right;
}

.items_group_main .section-details--info {
  width: 64% !important;
  padding-left: 25px;
}

.items_group_main .item-details-images-container>img {
  height: 346px;
}

.items_group_main .item-details-images-container {
  height: 100%;
  padding-left: 16px;
  padding-top: 8px;
}

.items_group_main .bottom_line {
  border-top: 1px solid #dfe1e5 !important;
}

.items_group_main .heading_font {
  font-size: 1.38rem !important;
  font-weight: 400 !important;
  line-height: 2.6rem;
  width: 55% !important;
}

.item-group-main {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
}

.item-group-main .item-tag {
  min-width: 20px;
  line-height: 19px;
  padding: 6px 8px;
  word-break: break-word;
  font-size: 13px;
}


.items_group_main .semi-bold {
  font-weight: 400;
  /* width: 55% !important; */
  word-break: break-all;
}

.items_group_main .field-row {
  line-height: 30px;
}

.border-right-set {
  border-right: 1px solid rgb(241, 241, 241);
}

.item-group-fragment,
.items_group_main+.item-groups-table {
  width: 100%;
}

.item-group-fragment .close_icon {
  right: 2rem;
  top: 14px;
}

.border-right-set .group_items_table th {
  font-size: 14px;
  vertical-align: middle;
}

.border-right-set .group_items_table td.pointerIn {
  padding: 3px 28px !important;
}

.border-right-set .group_items_table tr:nth-child(even) {
  background: #fcfcf9;
}

.border-right-set .group_items_table tr:hover {
  /* background: #DCDEE0 !important; */
  cursor: pointer;
}

.border-right-set .height_custom_item_group {
  height: 100%;
  max-height: 100%;
  /* margin-bottom: 20px; */
}

.border-right-set .group_items_table th:first-child {
  width: 35%;
}

.border-right-set .layout--box {
  margin-bottom: 0;
}

p.desc-set {
  line-height: 20px;
  text-align: justify;
  word-break: break-word;
}

.border-right-set .group_items_table td.pointerIn:first-child {
  padding: 3px 8px !important;
}

.open-stock-distribution {
  margin-right: 3px;
}

.brand_top {
  float: left;
  width: 100%;
}

/* .item-group-main .item-tag:nth-child(3n + 1) {
  margin-left: 0;
} */

.view_item_groups_popup .section-details--info {
  width: 56% !important;
}

.view_item_groups_popup .detais-component-main .float-left.w-70 {
  width: 100% !important;
}

.items_group_main+a.item_view_click_btn+.item-groups-table {
  margin-top: 5px;
}

.group-table-set th {
  font-size: 14px;
}

.opening_stock_button {
  float: right;
  margin-right: 15px;
}

.stock_distribution_popup tr th:first-child,
.stock_distribution_popup tr td:first-child {
  width: 35%;
}

.stock_distribution_popup tr th:nth-child(2),
.stock_distribution_popup tr td:nth-child(2) {
  width: 15%;
}

.stock_distribution_popup tr th:nth-child(3),
.stock_distribution_popup tr td:nth-child(3) {
  width: 25%;
}

.stock_distribution_popup tr th:nth-child(4),
.stock_distribution_popup tr td:nth-child(4) {
  width: 12%;
  /* padding-right: 5% !important; */
}

.td-set {
  width: 4%;
}

.stock_distribution_popup tr th:last-child(5),
.stock_distribution_popup tr td:last-child(5) {
  width: 10%;
}

.table_row_desc td.left-align.no-padding:nth-child(5) .form-field,
.table_row_desc td.left-align.no-padding:nth-child(6) .form-field,
.table_row_desc td.left-align.no-padding:nth-child(7) .form-field {
  min-width: 10rem;
}

.table_row_desc td.left-align.no-padding:nth-child(8) .form-field {
  min-width: 10rem;
}

.warehouse_name {
  display: flex;
  align-items: center;
}

.stock_distribution_popup svg.show-icon {
  display: none;
}

.stock_distribution_popup .ims-modal--title span:nth-child(1):before {
  content: '\f201';
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
  color: #000;
  padding-right: 13px;
  font-size: 16px;
}

.stock_distribution_popup .ims-modal--title span span {
  display: none;
}

.table_row_desc.item-group-items td.left-align.no-padding:nth-child(5),
.table_row_desc.item-group-items td.left-align.no-padding:nth-child(6),
.table_row_desc.item-group-items td.left-align.no-padding:nth-child(7) {
  width: 20% !important;
}

.border-right-set,
.items_details .float-left.w-70 {
  border-right: 0 !important;
}
.view_items_popup .tab-component-sub-tab,
.view_items_popup .tab-component--content {overflow: unset !important;}

.expand-item-groups-table th:nth-child(3) {
  width: 16.4%;
}

.expand-item-groups-table td:nth-child(3) div {
  padding: 0.8rem 0.9rem 0.8rem 0rem !important;
}

/* modifying */
.expand-item-groups-table th:nth-child(6) {
  width: 10%;
}

.expand-item-groups-table th:nth-child(5) {
  width: 5%;
}

.expand-item-groups-table th:nth-child(3) {
  width: 28%;
}

.expand-item-groups-table th:nth-child(4) {
  width: 21%;
}

.expand-item-groups-table td:nth-child(4) div {
  padding: 0.8rem 0.9rem 0.8rem 0rem !important;
}

.expand-item-groups-table th:nth-child(2) {
  width: 30%;
}

.__notItemsFound {
  clear: both;
  text-align: center;
  font-size: 20px;
  padding: 20px 0;
  padding-block: 20px;
  font-weight: 500;
}

/* @media (max-width: 1160px) {
  .ui-itemgroup-icon .close_icon {
    position: static;
    padding: 0;
    text-align: right;
    margin-right: 24px;
    margin-top: 10px;
  }
} */
@media(max-width:1600px) {
  .items_group_main .semi-bold {
    width: 70% !important;
  }

  .items_group_main .section-details--info {
    width: 54% !important;
  }
}

@media(max-width:1199px) {
  .ui-itemgroup-icon .section-details--info {
    width: 54% !important;
  }
}

@media (max-width: 1024px) {
  .ui-itemgroup-icon .section-details--info {
    width: 100% !important;
  }
}

@media (max-width: 768px) {
  .float-left.w-80.border-right-set.item-group-single {
    width: 100% !important;
    padding-right: 20px;
  }
}