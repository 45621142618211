.page-view-count {
  /* display: flex;
  align-items: center; */
  float: left;
}

.pagination-route .page-view-dropdown-container {
  margin: 0 0 0 0rem;
  width: 7rem;
}

.page-view-dropdown-body.up .Select-menu-outer {
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.06);
  margin-bottom: -1px;
  margin-top: 0;
  top: auto;
  bottom: 100%;
}

.pagination-route .Select-control {
  height: 2rem;
  background-color: #f0f2f5;
}

.pagination-route .Select-input {
  height: 3.2rem;
}

.pagination-route .Select-control .Select-value {
  font-size: 1.2rem;
}
.css-1okebmr-indicatorSeparator {
  display: none;
}

@media (max-width: 1536px) {
  .pagination-route .page-view-dropdown-container {
    width: 5rem;
  }
  .integration-container .pagination-route .page-view-dropdown-container{
    width: 7rem;
  }
}
