/*===========================
   root
=============================*/
:root {
  --blue-color: #306ab4;
  --green-color: #33a348;
  --bg-gray: #e7e8eb;
  --gray-color: #dfe1e5;
  --gray-text: #a8a8a8;
}

/*===========================
   General Display Classes
=============================*/
.d-none {
  display: none;
}
.pb-20{
  padding-bottom: 20px;
}

.d-block {
  display: block;
}

html body .d-flex {
  display: flex;
}

.space-between {
  justify-content: space-between;
}

.flex-end {
  justify-content: flex-end;
}

.align-center {
  align-items: center;
}

.conent-center {
  justify-content: center;
}

.flex-column {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-25 {
  flex: 0 0 25%;
  width: 25%;
}

.flex-33 {
  flex: 0 0 33.33%;
  width: 33.33%;
}

.flex-100 {
  flex: 0 0 100%;
  width: 100%;
}

.flex-66 {
  flex: 0 0 66.66%;
  width: 66.66%;
}

.flex-50 {
  flex: 0 0 50%;
  width: 50%;
}

.flex-60 {
  flex: 0 0 60%;
  width: 60%;
}

.flex-40 {
  flex: 0 0 40%;
  width: 40%;
}

.flex-30 {
  flex: 0 0 30%;
  width: 30%;
}

.flex-70 {
  flex: 0 0 70%;
  width: 70%;
}

.clearfix:before,
.clearfix:after {
  content: '';
  display: table;
}

.clearfix:after {
  clear: both;
}
.view-details-popup .ims-modal--title span,
.popup-title-align .edit_payment_modal_title , 
.popup-title-align .payment_modal_title{
  display: flex;
  align-items: center;
}

/*===========================
   General Buttons
=============================*/
.ecom-btns {
  text-align: right;
  margin-bottom: 20px;
}

.w-100px {
  min-width: 100px;
}

.w-130px {
  min-width: 130px;
}

.w-83px {
  min-width: 83px;
}

.w-64px {
  min-width: 64px;
}

.h-38 {
  height: 38px;
}

.btn-style {
  padding: 5px 20px;
}

.btn-popup {
  padding: 5px 16px;
}

.btn-popup,
.btn-style {
  display: inline-block;
  font-size: 14px;
  line-height: 28px;
  color: #fff;
  transition: 0.3s;
}

.btn-blue {
  background-color: var(--blue-color);
}

.text-blue {
  color: var(--blue-color);
}

.btn-green {
  background-color: var(--green-color);
  transition: 0.3s;
}

.btn-white {
  background-color: #fff;
  border: 1px solid #f0f2f5;
  color: #000;
  padding: 4px 20px;
}

.btn-white:hover {
  border-color: #e2e2e2;
}

.text-gray {
  color: var(--gray-text);
}

.bg-gray {
  background-color: var(--bg-gray);
}

.line-clamp {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.popup-border-bottom {
  border-bottom: 1px solid #dfe1e5;
}

.popup-border-top {
  border-top: 1px solid #dfe1e5;
}

.item-sync-popup .cancel_button,
html body .btn-gray {
  background: var(--background);
  box-sizing: border-box;
  box-shadow: var(--button-box-shadow);
  /* color: var(--text-secondary); */
  transition: 0.3s;
  color: #303940;
}

.item-sync-popup .cancel_button:hover,
.btn-blue:hover,
.btn-green:hover,
.btn-gray:hover {
  background-color: #fff;
}

.item-sync-popup .cancel_button:hover,
.btn-blue:hover,
.btn-green:hover {
  color: #000;
  box-shadow: var(--hover-box-shadow);
}

.syncModal button {
  line-height: inherit;
}

/*===========================
   General inputs
=============================*/
.cus-input,
.cus-select {
  outline: none;
  padding-left: 10px;
}

.cus-select {
  height: 35px;
}

.cus-input {
  height: 40px;
  border: 1px solid var(--gray-color);
}

.cus-input::placeholder {
  color: #808080;
}

input {
  outline: none;
}

/* .medium-modal{
   max-width: 740px;
} */

/*===========================
   General borders
=============================*/
.br-2 {
  border-radius: 2px;
}

.br-5 {
  border-radius: 5px;
}

/*===========================
   General margin
=============================*/

.mr-15 {
  margin-right: 15px;
}

.mt_0 {
  margin-top: 0 !important;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

/*===========================
   General Padding
=============================*/
.pt-20 {
  padding-top: 20px;
}

.pt-5 {
  padding-top: 5px;
}

.pb-5 {
  padding-bottom: 5px;
}

.pt-10 {
  padding-top: 10px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pr-5 {
  padding-right: 5px;
}

.pl-5 {
  padding-left: 5px;
}

.pl-0 {
  padding-left: 0;
}

.p-10 {
  padding: 10px;
}

.pr-10 {
  padding-right: 3px;
}

.pl-10 {
  padding-left: 10px;
}

.pr_10 {
  padding-right: 10px;
}

.w-33 {
  width: 33.33%;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pt-8 {
  padding-top: 8px;
}

.pt-0 {
  padding-top: 0;
}

/*===========================
   General Table
=============================*/
.general-table {
  width: 100%;
}

.general-table thead {
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.16);
}

.general-table th {
  font-size: 14px;
  font-weight: bold;
  padding: 12px;
}

.general-table th:first-child,
.general-table td:first-child {
  padding-left: 20px;
  padding-right: 10px;
}

.general-table td {
  padding: 9px 0;
}

/*===========================
Custom Checkbox    
=============================*/
.checkbox_wrapper {
  padding: 15px 0;
}

.check_box {
  display: flex;
  align-items: center;
}

.check_box label {
  margin-bottom: 0;
  display: inline-block;
  margin: 0 20px 0 10px;
}

html body .default-checkbox {
  position: relative;
  width: 18px;
  height: 18px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: #fff !important;
  border: 1px solid #cbcbcb !important;
  border-radius: 2px;
  cursor: pointer;
  flex: 0 0 18px;
  flex-shrink: 0;
  outline: none !important;
  margin-top: 0 !important;
}

.default-checkbox:checked {
  color: #fff !important;
  background-color: #000 !important;
  border-color: #000 !important;
}

.default-checkbox::before {
  position: absolute;
  content: '';
  display: block;
  left: 4px;
  top: 0;
  width: 7px;
  height: 12px;
  border-color: #fff;
  border-style: solid;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
  opacity: 1;
}

/*==============================
Custom pop-up header and footer    
================================*/
.ims-modal.cus-popup .ims-modal--title,
.ims-modal.item-sync-popup .ims-modal--title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
  border-bottom: 1px solid #dfe1e5;
  /* margin-bottom: 10px; */
}

.cus-popup .ims-modal--title .close-button {
  width: unset;
  float: unset;
}

.cus-popup .ims-modal--body {
  padding: 0;
}

.cus-popup .action-btns {
  padding: 15px 0;
  text-align: right;
}

/*===========================
Custom Input and checkbox     
=============================*/
.input-wrapper {
  display: flex;
  align-items: center;
  padding: 15px 0;
}

.input-wrapper .input_box {
  max-width: 225px;
  width: 100%;
  margin: 0 12px;
}

.input-wrapper .input_box input,
.input-wrapper .input_box select {
  width: 100%;
}

.input-wrapper .input_box input:hover {
  border-color: #878787;
}

.cus-label {
  align-self: flex-start;
  margin-top: 10px;
}

/*============================
status styles   
==============================*/
html body .status {
  padding: 0.7rem 0.5rem;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.475rem;
  font-size: 11px !important;
  font-weight: bold;
}

th.status a {
  height: 100%;
  display: flex;
  align-items: center;
  /* justify-content: center; */
}

/*============================
Media Query for global pop-up   
==============================*/

@media screen and (min-width: 1200px) {
  .medium-modal {
    max-width: 740px;
    width: 100%;
  }
}

/*============================
Media Query for Form redesign   
==============================*/
@media (max-width: 1024px) {
  .flex-25 {
    flex: 0 0 33.33%;
    width: 33.33%;
  }
}

@media (max-width: 890px) {

  .flex-33,
  .flex-25 {
    flex: 0 0 50%;
    width: 50%;
  }

  .flex-66 {
    width: 100%;
    flex: 0 0 100%;
  }
}

@media (max-width: 768px) {

  .flex-40,
  .flex-60,
  .flex-50,
  .flex-25 {
    flex: 0 0 100%;
    width: 100%;
  }
}